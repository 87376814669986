import React from 'react'
import Footer from '../components/footer'
import Layout from '../components/layout'
import Title from '../components/title'
import {Helmet} from "react-helmet-async"

export default function Mediation(){

    return(
        <Layout>
            <Helmet>
                <title>Médiation</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/mediation`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Qu'est ce que la médiation?" />
            </Helmet>
            <Title title={"Qu'est ce que la médiation ?"} />
                
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <p className="mb-5">La médiation est un mode alternatif de règlement des litiges et des différends impliquant l’intervention d’un tiers C’est est un processus amiable, volontaire et confidentiel.</p>
                <p className="mb-5">La médiation est codifiée depuis 1995, articles 131-1 à 131-15 du Code de Procédure Civile.</p>
                <p className="mb-5">Il s’agit de proposer aux parties en conflit l’intervention d’un tiers indépendant et impartial formé à la médiation, qui les aide à trouver une solution négociée et conforme aux intérêts des deux parties afin de mettre fin au litige.</p>
                <p className="mb-5">Le médiateur n’est ni juge, ni arbitre, mais plutôt un « facilitateur » dont la mission est de permettre les négociations, communications entre les parties, afin de les aider à trouver elles-mêmes une solution à leur différend.</p>
                <p className="mb-5">Le médiateur doit suivre une formation aux techniques de médiation et se soumettre à une évaluation qui constitue un véritable examen. C’est un professionnel qui doit en permanence s’adapter aux situations et aux personnes.</p>
                <p className="mb-5">Il existe plusieurs types de médiation, en fonction de la situation : la médiation inter-entreprises, la médiation intra-entreprise ou médiation sociale, la médiation collective ou encore la médiation de la consommation.</p>
                <p className="mb-5">Quels sont les avantages de la médiation :</p>
                <ul className="list-disc px-5 mb-5">
                    <li>Gain de temps</li>
                    <li>Coût maîtrisé</li>
                    <li>Confidentialité des discussions et de l’accord trouvé</li>
                    <li>Préservation des relations entre les parties</li>
                    <li>Créativité des solutions</li>
                    <li>Maîtrise totale de la solution</li>
                    <li>Certitude quant à la mise en œuvre des accords</li>
                    <li>Suspension des délais de prescription</li>
                    <li>Domaines d’application de la médiation</li>
                </ul>
                <p className="mb-5">Que ce soit pour des conflits rencontrés avec un cocontractant (un fournisseur, un prestataire, un client, un franchisé, etc.), des conflits internes à l’entreprise, ou des conflits collectifs, la médiation offre des solutions dans bon nombre de domaines. La médiation peut être réalisée de façon conventionnelle et est alors initiée par les parties elles-mêmes, spontanément à la naissance du litige ou bien parce qu’une clause de médiation a été insérée dans le contrat qui les lie. Elle peut être ordonnée par un juge judiciaire et elle est alors ordonnée par un juge civil ou commercial, à sa propre initiative et avec l’accord des parties ou à la demande de celles-ci ou de leurs conseils.</p>
                <p className="mb-5">En ce qui me concerne, j’ai suivi la formation complète en médiation et pratique la médiation depuis quelques temps.</p>
                <p className="mb-5">J’interviens essentiellement dans mon domaine de compétences au niveau des litiges entre professionnels ou entre particuliers vis-à-vis de conflits touchant le bâtiment et son contexte ainsi que les conflits de voisinage.</p>

            </div>
            <Footer />
        </Layout>
    )
}