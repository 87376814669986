import React from 'react'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Home from '../pages/home'
import ExpertiseBatiment from '../pages/expertiseBatiment'
import Mediation from '../pages/mediation'
import Articles from '../pages/articles'
import Contact from '../pages/contact'
import MentionsLegales from '../pages/mentionsLegales'
import NoMatch from '../pages/nomatch'
import MaitriseDouvrage from '../pages/maitriseDouvrage';

export default function CustomRouter(){
    return (
        <Router>
            <div>
                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/articles">
                        <Articles />
                    </Route>
                    <Route exact path="/expertise-batiment">
                        <ExpertiseBatiment />
                    </Route>
                    <Route exact path="/mediation">
                        <Mediation />
                    </Route>
                    <Route excat path="/assistance-maitrise-d-ouvrage">
                        <MaitriseDouvrage />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/mentions-legales">
                        <MentionsLegales />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="*">
                        <NoMatch />
                    </Route>
                </Switch>
            </div>
        </Router>
    )
}
