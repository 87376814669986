import React from 'react'
import Divider from '@mui/material/Divider';

export default function DividerMenu(){

    return(
        <div className="my-1 px-6">
            <Divider style={{backgroundColor: 'rgba(255, 255, 255, 0.25)'}} />
        </div>
    )
}