import React from 'react'

export default function BackgroundHomePage(){

    return(
        <section id="banner">
            <div id="inner">
                <header className="text-white flex items-center h-full">
                    <div className="text-center m-auto w-full px-5">
                        <h1 className="text-6xl font-bold">ABC EXPERTS</h1>
                        <div className="text-2xl font-bold m-auto">
                            <p className="my-10"><strong>Cabinet d’expertise en bâtiment et évaluation immobilière</strong> dans la RÉGION SUD</p>
                            <div className="md:flex md:justify-center">
                                <p><strong>Françoise Clément</strong></p>
                                <p className="hidden mx-2 md:inline"> - </p>
                                <p>06 03 22 42 17</p>
                            </div>
                            
                        </div>
                    </div>
                </header>
            </div>
        </section>
    )
}