import React from 'react'
import Layout from '../components/layout'
import Title from '../components/title'
import emailjs from 'emailjs-com'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar'
import {useState, createRef} from 'react' 
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ReCAPTCHA from "react-google-recaptcha"
import Footer from '../components/footer'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import ObjectSelect from '../components/objectSelect'
import {Helmet} from "react-helmet-async"

const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .required("Veuillez renseigner votre nom"),
    email: Yup.string()
        .required("Veuillez renseigner votre adresse email")
        .email("Veuillez entrer une adresse mail valide"),
    message: Yup.string()
        .required("Veuillez entrer un message"),
})

export default function Contact(){
    const recaptchaRef = createRef()
    const [object, setObject] = useState('')
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState('success')
    const [response, setResponse] = useState(null)
    const [recaptcha, setRecaptcha] = useState(null)

    const handleChangeObject = (event) => {
        setObject(event.target.value)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleChangeCaptcha = (event) => {
        setRecaptcha({'g-recaptcha-response':event})
    }

    return(
        <Layout>
            <Helmet>
                <title>Contacter Abcexperts</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/contact`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Contacter ABCEXPERTS" />
            </Helmet>
            <Snackbar open={open} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={8000} onClose={handleClose}>
                <Alert severity={status} >
                    {response}
                </Alert>
            </Snackbar>
            
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <Title title={"Pour me contacter"} />
                <Formik
                    initialValues={{name: '', email: '', message: ''}}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { resetForm }) => {
                        values.object = object
                        values = {...values, ...recaptcha}
                        console.log("values : ",values)
                        console.log("send Message")
                        emailjs.send('service_0cyupgw', 'template_uiz90zk', values, 'user_ItEwEdN5R74NNNk7A58uN')
                                .then((result) => {
                                    console.log("message sent : ", result)
                                    setStatus('success')
                                    setResponse('Message envoyé avec succès')
                                    resetForm()
                                    setOpen(true)
                                }, (error) => {
                                    console.log("error : ", error)
                                    setStatus('error')
                                    setResponse('Erreur lors de l\'envoie. Veuillez réessayer ultérieurement.')
                                    setOpen(true)
                                })
                     
                    }}
                >
                {formik => (
                <Form className="text-center" onSubmit={formik.handleSubmit} >
                    <div className="container my-10">
                        <div className="md:flex md:space-x-5">
                            <div className="my-4 w-full">
                                <TextField fullWidth type="text"
                                    name="name" label="Nom et prénom" 
                                    variant="outlined" 
                                    helperText={formik.errors.name && formik.touched.name? (formik.errors.name) : null} 
                                    error={formik.errors.name && formik.touched.name? true : null} 
                                    onChange={formik.handleChange} value={formik.values.name}/> 
                            </div>
                            <div className="my-4 w-full">
                                <TextField fullWidth type="text"
                                    name="email" label="Email" 
                                    variant="outlined" 
                                    helperText={formik.errors.email && formik.touched.email? (formik.errors.email) : null} 
                                    error={formik.errors.email && formik.touched.email? true : null} 
                                    onChange={formik.handleChange} value={formik.values.email}/> 
                            </div>
                        </div>
                        <div className="my-4 w-full text-left">
                            <ObjectSelect object={object} action={handleChangeObject} />
                        </div>
                        <div className="my-4">
                            <FormControl className="mt-4 w-full">
                                <TextField placeholder={"Veuillez saisir votre message ici"}   
                                    maxRows={12} minRows={6} fullWidth multiline
                                    variant="outlined" 
                                    name="message" 
                                    helperText={formik.errors.message && formik.touched.message? (formik.errors.message) : null} 
                                    error={formik.errors.message && formik.touched.message? true : null} 
                                    onChange={formik.handleChange} value={formik.values.message}
                                />
                            </FormControl>
                        </div>
                        <div className="flex justify-center my-5" >
                            <ReCAPTCHA
                                sitekey={`${process.env.REACT_APP_G_RECAPTCHA_SITE_KEY}`}
                                name="recaptcha"
                                ref={recaptchaRef}
                                onChange={handleChangeCaptcha}
                            />
                        </div>
                        <div>
                            <Button variant="contained" disabled={recaptcha === null} color="success" type="submit">
                                Envoyer
                            </Button> 
                        </div>
                    </div>
                </Form>
                )}
                </Formik>
            </div>
            <Footer />
        </Layout>
    )
}