import React from 'react'
import Layout from '../components/layout'
import MainImage from '../components/mainImage'
import Title from '../components/title'
import Divider from '@mui/material/Divider'
import {Link} from 'react-router-dom'
import Footer from '../components/footer'
import {Helmet} from "react-helmet-async"

export default function ExpertiseBatiment(){

    return(
        <Layout>
            <Helmet>
                <title>Expertise bâtiment</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/expertise-batiment`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Expertise bâtiments ABCEXPERTS" />
            </Helmet>
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <Title title={"L'expert en bâtiment"} />
                <div className="">
                    <MainImage imageUrl="../img/mur_fissure.jpg" alt="mur fissure" />
                </div>
                <div className="max-w-2xl m-auto my-10">
                    <p className="mb-5">L’expert en techniques du bâtiment doit être un vrai professionnel, spécialiste de l’expertise relative à la construction, ainsi qu’au respect des règles de l’art, des DTU, capable de reconnaître les malfaçons...</p>
                    <p className="mb-5">Cette spécialité professionnelle est acquise par des études techniques supérieures, une formation continue, par une véritable expérience personnelle de terrain et une pratique de l’expertise bâtiment qui se développe chaque jour.</p>
                    <p className="mb-5">L’expert est apte à dresser un rapport d’expertise détaillé qui vous permettra d’aller en justice.</p>
                    <p className="mb-5">L’expert assure un rôle important par son expertise et expérience dans le secteur du bâtiment que ce soit en conseil en expertise technique ou en assistance concernant aussi bien une maison, un appartement ou un bâtiment commercial ou industriel.</p>
                    <p className="mb-5">Par ses compétences l’expert bâtiment dans son domaine, saura conseiller, répondre aux questions, appréciations et suggestions de travaux à réaliser et les mesures techniques de sauvegarde à prendre pour préserver et maintenir un bien construit en bon état.</p>
                </div>
                <p className="mb-5 max-w-xl m-auto"><img src="./img/batiment.png" alt="batiment déterioré" /></p>  
                <div className="max-w-2xl m-auto my-10">
                    <p className="mb-5">L’expert bâtiment vérifie donc pour résumer :</p>
                    <ul className="list-disc px-5 mb-5">
                        <li>Les fondations : Problèmes d’infiltration d’eau, de fissures ou d’effritement Moisissures au bas des murs du sous-sol, les affaissements du sol, les systèmes de drainage.</li>
                        <li>La charpente : Les affaissements des planchers ou poutres, les taches noirâtres, le soulèvement des planchers de bois, les craquements</li>
                        <li>Les dalles sur sols : Les fissures, les indices de gonflements, les taches blanchâtres, la présence pyrite et de shale...</li>
                    </ul>
                </div>     
                <Divider />   
                <div className="max-w-2xl m-auto my-10">
                    <p className="mb-5">Des problèmes de fissures, de lézardes, d’infiltration dans votre logement ?</p>
                    <p className="mb-5">Vous avez des doutes sur la solidité de votre villa ? Vous avez l’impression que l’humidité est constante dans votre appartement ? Les travaux que vous venez de réaliser ne vous semblent pas répondre à ce que vous attendez ?</p>
                    <p className="mb-5">Pensez expertise.</p>
                </div>
                
                <div className="mb-20 m-auto w-full text-center">
                    <Link to="/contact" className="btn">Demander une expertise</Link>
                </div>
            </div>
           <Footer />
        </Layout>
    )
}