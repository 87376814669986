import React from 'react'
import Layout from '../components/layout'
import Title from '../components/title'
import ArticleSummary from '../components/articleSummary'
import {Helmet} from "react-helmet-async"
import Footer from '../components/footer'

export default function Articles(){

    return(
        <Layout>
            <Helmet>
                <title>Articles</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/articles`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Liste des articles ABCEXPERTS" />
            </Helmet>
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <Title title={"Mes articles"} />
                <div className="mt-10 grid grid-cols-1 gap-10 md:grid-cols-2">
                    <ArticleSummary title="Comment l'immobilier a changé au cours de l'année 2020" imageSrc="../img/bien_immobilier_2.jpg" imageAlt="image alt"
                        intro="Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros."
                        url="/articles"
                    />
                    <ArticleSummary title="title" imageSrc="../img/bien_immobilier_2.jpg" imageAlt="image alt"
                        intro="Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros."
                        url="/articles"
                    />
                    <ArticleSummary title="title" imageSrc="../img/bien_immobilier_2.jpg" imageAlt="image alt"
                        intro="Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros."
                        url="/articles"
                    />
                    <ArticleSummary title="title" imageSrc="../img/bien_immobilier_2.jpg" imageAlt="image alt"
                        intro="Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros."
                        url="/articles"
                    />
                    <ArticleSummary title="title" imageSrc="../img/bien_immobilier_2.jpg" imageAlt="image alt"
                        intro="Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros."
                        url="/articles"
                    />

                </div>

            </div>
            <Footer />
        </Layout>
    )
}