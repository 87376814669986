import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

export default function ObjectSelect(props){

    const objects = [
        "Demande d'expertise pour valeur d'un bien", 
        "Demande d'expertise suite à un sinistre",
        "Question spécifique",
        "Autre demande",
    ]
    const choices = [];


    for (let object of objects) {
        choices.push(<MenuItem key={object} value={object}>{object}</MenuItem>);
    }

    return(
        <FormControl fullWidth>
            <InputLabel>Sujet de la demande</InputLabel>
            <Select
                labelId="province-select-label"
                fullWidth
                name="subject"
                value={props.object}
                label="Sujet de la demande"
                onChange={props.action}
            >
                {choices}
            </Select>
        </FormControl>
    )
}
