import React from 'react'
import {Link} from 'react-router-dom'
import GoogleIcon from '@mui/icons-material/Google'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InfoIcon from '@mui/icons-material/Info';
export default function Footer(){

    return(
        <div id="footer" className="text-center">
            <div className="mb-5 flex justify-center space-x-10">
                
                <a href="https://www.linkedin.com/in/françoise-clement-8a9159a3" target="_blank" className="border border-gray-300 rounded-full">
                    <div className="border-4 border-white rounded-full p-2">
                        <LinkedInIcon fontSize="large" />
                    </div>
                </a>
                <a href="https://user.clicrdv.com/abc-experts" target="_blank" className="border border-gray-300 rounded-full">
                    <div className="border-4 border-white rounded-full p-2">
                        <GoogleIcon fontSize="large" />
                    </div>
                </a>
                <a href="https://www.societe.com/societe/abc-experts-838788677.html" target="_blank" className="border border-gray-300 rounded-full">
                    <div className="border-4 border-white rounded-full p-2">
                        <InfoIcon fontSize="large" />
                    </div>
                </a>
               
            </div>
            <p>© ABCEXPERTS. Tout droits réservés. Intégration par Johan Clément. 
                <Link to="/mentions-legales">
                    <span className=" ml-1 underline linkBlue">Mentions légales</span>
                </Link>
            </p>
        </div>
    )
}