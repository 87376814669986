import CustomRouter from './components/customRouter'
import {HelmetProvider} from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
        <CustomRouter />
    </HelmetProvider>
  )
}

export default App
