import React from 'react'
import BackgroundHomePage from '../components/backgroundHomePage'
import Layout from '../components/layout'
import Footer from '../components/footer'
import PortraitImage from '../components/portraitImage'
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet-async"

export default function Home(){

    return(
        <Layout>
            <Helmet>
                <title>Abcexperts</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Bienvenue sur le site ABCEXPERTS" />
            </Helmet>
            <BackgroundHomePage />
            <div className="px-5 md:max-w-4xl m-auto mt-20">
                <div className="md:flex md:justify-center md:space-x-10">
                    <PortraitImage imageUrl="../img/francoise_clement.jpg" alt="Françoise Clément experte en bâtiment près la cour d'appel d'aix en provence" />
                    <div>
                        <p className="mt-2 font-bold text-2xl text-black mb-10">Qui suis-je ?</p>
                        <p className="mb-10">Après une longue expérience dans le domaine du bâtiment, je me consacre désormais à l’expertise en technique du bâtiment et évaluation immobilière.</p>
                        <p className="mb-10"><strong className="font-bold">Expert inscrit près la cour d'appel d'Aix en Provence</strong>, j’ai suivi la formation de responsable de programme immobilier auprès de L’ICH de Marseille ainsi qu’en évaluation immobilière afin d’obtenir les deux diplômes et pouvoir réaliser des évaluations et des expertises.</p>
                        <p className="mb-10">Depuis ma formation initiale je veille à me tenir à jour sur tous les sujets auprès d’organismes spécialisés et compétents, que ce soit en pathologies du bâtiment ou en valeur vénale.</p>
                        <p className="mb-10">J’effectue une mise à jour constante et une veille aussi bien juridique que technique afin de pouvoir apporter mon professionnalisme dans chaque cas et à chaque instant.</p>
                    </div>
                </div>
                <div className="md:flex md:justify-center md:space-x-10">
                    <PortraitImage imageUrl="../img/pierre_jouffret.jpg" alt="Pierre Jouffret expert près la cour administrative d'appel de Marseille" />
                    <div>
                        <p className="mb-10">Je travaille en partenariat avec Pierre JOUFFRET, <strong className="font-bold">expert près la cour administrative d'appel de Marseille</strong>. Après une expérience de plus de 50 ans dans le monde du bâtiment, il s’est tourné vers l’expertise en techniques du bâtiment et réalise aussi bien des expertises amiable et privées que des expertises judiciaires auprès des tribunaux de Draguignan, Toulon, Grasse, Nice, Marseille et Aix en Provence.</p>
                        <p className="mb-10">Toutes les expertises sont faites soit seul soit en binôme, mais elles bénéficient de l’avis des deux experts.</p>
                        <p className="mb-10">Nous nous sommes formés à la médiation afin d’apporter notre concours aux personnes et sociétés dans la résolution amiable des conflits ou litiges. Nous intervenons essentiellement dans les différends concernant le domaine du bâtiment, techniques ou règlementaires, (Par exemple un différend entre un architecte et le maitre d’ouvrage, les conflits de voisinage…) mais nous pouvons aussi intervenir dans la résolution des conflits interne aux entreprises ou hors entreprise.</p>
                        <div className="mb-20">
                            <Link to="/contact" className="btn">Demander une expertise</Link>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
       </Layout>
    )
}