import React from 'react'
import Layout from '../components/layout'
import Title from '../components/title'
import ArticleSummary from '../components/articleSummary'
import {Helmet} from "react-helmet-async"
import Footer from '../components/footer'

export default function MaitriseDouvrage(){

    return(
        <Layout>
            <Helmet>
                <title>Articles</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/assistance-maitrise-d-ouvrage`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Assistance en maitrise d'ouvrage ABCEXPERTS" />
            </Helmet>
            <div className="px-5 md:max-w-4xl m-auto my-10">
                <Title title={"Assistance en maitrise d'ouvrage"} />
                <p className="my-40">Contenu à rajouter</p>

            </div>
            <Footer />
        </Layout>
    )
}