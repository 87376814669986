import React from 'react'
import Layout from '../components/layout'
import Title from '../components/title'
import {Helmet} from "react-helmet-async"
import Footer from '../components/footer'

export default function MentionsLegales(){

    return(
        <Layout>
            <Helmet>
                <title>Mentions légales</title>
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/mentions-legales`} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content="Mentions légales" />
            </Helmet>
            <section>
                <Title title={"Conditions générales d'utilisation et mentions légales"} />
                <div className="max-w-4xl m-auto my-10">
                    <p className="mb-5">Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site : <a href="https://www.abcexperts.fr" >www.abcexperts.fr</a> les informations suivantes :</p>
                    
                    <p className="mb-5"><span className="font-bold">1. Informations légales :</span></p>
                    
                    <p className="mb-5">Statut du propriétaire : <span className="font-bold">particulier</span><br />
                    Le Propriétaire est : <span className="font-bold">Françoise Clément</span><br />
                    Adresse postale du propriétaire : <span className="font-bold">26A chemin des amandiers 13180 Gignac-la-Nerthe</span><br />
                    <br />
                    Le Créateur du site est : <span className="font-bold">Johan Clément</span><br />
                    Le Responsable de la  publication est : <span className="font-bold">Johan Clément</span><br />
                    Contacter le responsable de la publication : <span className="font-bold">johanclement88[a]hotmail.fr</span><br />
                    Le responsable de la publication est une<span className="font-bold"> personne physique</span><br />
                    <br />
                    Le Webmaster est  : <span className="font-bold">Johan Clément</span><br />
                    Contacter le Webmaster :  <span className="font-bold"><a href="mailto:johanclement88[a]hotmail.fr?subject=Contact a partir des mentions lÃ©gales via le site www.abcexperts.fr">johanclement88[a]hotmail.fr</a></span><br />
                    L’hebergeur du site est : <span className="font-bold">OVH 2 rue Kellermann 59100 Roubaix</span><br />
                    <span className="font-bold"><u>CREDIT :</u> </span> Les mentions légales ont étés générées par<span className="font-bold"> <a href="https://www.generer-mentions-legales.com/generateur-mentions-legales.html">générateur de mentions legales</a></span><br />
                    Personnalisez votre <span className="font-bold"><a href="http://www.panneau-a-vendre.com/boutique/produits.php">Panneau à vendre</a></span></p>
                    
                    <p className="mb-5"> </p>
                    
                    <p className="mb-5"><span className="font-bold">2. Présentation et principe :</span></p>
                    
                    <p className="mb-5">Est désigné ci-après : <span className="font-bold">Utilisateur</span>, tout internaute se connectant et utilisant le site susnommé : <a href="https://www.abcexperts.fr" >www.abcexperts.fr</a>.<br />
                    Le site <span className="font-bold">www.abcexperts.fr</span><span className="font-bold"> </span>regroupe un ensemble de services, dans l'état,  mis à la disposition des utilisateurs. Il est ici précisé que ces derniers doivent rester courtois et faire preuve de bonne foi tant envers les autres utilisateurs qu'envers le webmaster du site www.abcexperts.fr. Le site www.abcexperts.fr est mis à jour régulièrement par Johan Clément.<br />
                    Françoise Clément s’efforce de fournir sur le site www.abcexperts.fr des informations les plus précises possibles (sous réserve de modifications apportées depuis leur mise en ligne), mais ne saurait garantir l'exactitude, la complétude et l'actualité des informations diffusées sur son site, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. En conséquence, l'utilisateur reconnaît utiliser ces informations données (à titre indicatif, non exhaustives et susceptibles d'évoluer) sous sa responsabilité exclusive.</p>
                                        
                    <p className="mb-5"><span className="font-bold">3. Accessibilité :</span><br />
                    <br />
                    Le site www.abcexperts.fr est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf interruption, programmée ou non, pour les besoins de sa maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au service, www.abcexperts.fr s’engage à faire son maximum afin de rétablir l’accès au service et s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.  N’étant soumis qu’à une obligation de moyen, www.abcexperts.fr ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du service.</p>
                    
                    <p className="mb-5"> </p>
                    
                    <p className="mb-5"><span className="font-bold">4. Propriété intellectuelle :</span></p>
                    
                    <p className="mb-5"><br />
                    Françoise Clément est propriétaire exclusif de tous les droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, tant sur la structure que sur les textes, images, graphismes, logo, icônes, sons, logiciels…<br />
                    Toute reproduction totale ou partielle du site www.abcexperts.fr, représentation, modification, publication, adaptation totale ou partielle de l'un quelconque de ces éléments, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Françoise Clément, propriétaire du site à l'email : francoise.clementdamour[a]gmail.com, à défaut elle sera considérée comme constitutive d’une contrefaçon et passible de poursuite conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
                    
                    <p className="mb-5"><br />
                    <span className="font-bold">5. Liens hypertextes et cookies :</span><br />
                    <br />
                    Le site www.abcexperts.fr contient un certain nombre de liens hypertextes vers d’autres sites (partenaires, informations …) mis en place avec l’autorisation de Françoise Clément. Cependant, Françoise Clément n’a pas la possibilité de vérifier l'ensemble du contenu des sites ainsi visités et décline donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites.<br />
                    L’utilisateur est informé que lors de ses visites sur le site www.abcexperts.fr, un ou des cookies sont susceptibles de s’installer automatiquement sur son ordinateur par l'intermédiaire de son logiciel de navigation. Un cookie est un bloc de données qui ne permet pas d'identifier l'utilisateur, mais qui enregistre des informations relatives à la navigation de celui-ci sur le site. <br />
                    Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de la refuser de la manière décrite à l’adresse suivante : <a href="http://www.cnil.fr">www.cnil.fr</a>. L’utilisateur peut toutefois configurer le navigateur de son ordinateur pour refuser l’installation des cookies, sachant que le refus d'installation d'un cookie peut entraîner l’impossibilité d’accéder à certains services. Pour tout bloquage des cookies, tapez dans votre moteur de recherche : bloquage des cookies sous IE ou firefox et suivez les instructions en fonction de votre version.</p>
                    
                    <p className="mb-5"><br />
                    <span className="font-bold">6. Protection des biens et des personnes - gestion des données personnelles :</span><br />
                    <br />
                    En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</p>
                    
                    <p className="mb-5">Sur le site www.abcexperts.fr, Françoise Clément ne collecte des informations personnelles ( suivant l'article 4 loi n°78-17 du 06 janvier 1978) relatives à l'utilisateur que pour le besoin de certains services proposés par le site www.abcexperts.fr. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site www.abcexperts.fr l’obligation ou non de fournir ces informations.<br />
                    Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition aux données personnelles le concernant. Pour l’exercer, adressez votre demande à www.abcexperts.fr par email : <span className="font-bold"><a href="mailto:johanclement88[a]hotmail.fr?subject=Contact ï¿½ partir des mentions lï¿½gales via le site www.abcexperts.fr">johanclement88[a]hotmail.fr</a></span> ou par écrit dûment signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.</p>
                    
                    <p className="mb-5">Aucune information personnelle de l'utilisateur du site www.abcexperts.fr n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat du site www.abcexperts.fr et de ses droits autorise Françoise Clément à transmettre les dites informations à l'éventuel acquéreur qui serait à son tour tenu à la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site www.abcexperts.fr.<br />
                    Le site www.abcexperts.fr est en conformité avec le RGPD voir notre politique RGPD  NULL.</p>
                    
                    <p className="mb-5">Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
                    <br/>
                    <p className="mb-5"><span className="font-bold"> 7. Gestion des données personnelles.</span></p>

                    <p className="mb-5">Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679). </p>

                    <p className="mb-5"><span className="font-bold">7.1 Responsables de la collecte des données personnelles</span></p>

                    <p className="mb-5">Pour les Données Personnelles collectées dans le cadre de la création du compte personnel de l’Utilisateur et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est : Johan Clément. <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>est représenté par Françoise Clément, son représentant légal</p>

                    <p className="mb-5">En tant que responsable du traitement des données qu’il collecte, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité.
                    Chaque fois que <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> traite des Données Personnelles, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> les traite.</p>
                        
                    <p className="mb-5"><span className="font-bold">7.2 Finalité des données collectées</span></p>
                        
                    <p className="mb-5"><a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> est susceptible de traiter tout ou partie des données : </p>

                    <ul>
                        
                        <li>pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l’utilisateur : données de connexion et d’utilisation du Site, facturation, historique des commandes, etc. </li>
                        
                        <li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé) </li>
                        
                        <li>pour améliorer la navigation sur le Site : données de connexion et d’utilisation </li>
                        
                        <li>pour mener des enquêtes de satisfaction facultatives sur <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> : adresse email </li>
                        <li>pour mener des campagnes de communication (sms, mail) : numéro de téléphone, adresse email</li>


                    </ul>

                    <p className="mb-5"><a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>
                        
                    <p className="mb-5"><span className="font-bold">7.3 Droit d’accès, de rectification et d’opposition</span></p>
                        
                    <p className="mb-5">
                    Conformément à la réglementation européenne en vigueur, les Utilisateurs de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> disposent des droits suivants : </p>
                    <ul>

                        <li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite </li>
                        
                        <li>droit de retirer à tout moment un consentement (article 13-2c RGPD) </li>
                        
                        <li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD) </li>
                        
                        <li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD) </li>
                        
                        <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD) </li>
                        
                        <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
                    </ul>

                    <p className="mb-5">Dès que <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.</p>
                        
                    <p className="mb-5">Si l’Utilisateur souhaite savoir comment <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> par écrit à l’adresse suivante : </p>
                    <p className="mb-5">johanclement88[a]hotmail.fr</p>
                        
                    <p className="mb-5">Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport). </p>

                    <p className="mb-5">
                    Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).</p>
                        
                    <p className="mb-5"><span className="font-bold">7.4 Non-communication des données personnelles</span></p>

                    <p className="mb-5">
                    <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>

                    <p className="mb-5">
                    <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> ne collecte aucune « données sensibles ».</p>

                    <p className="mb-5">
                    Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.</p>
                    <p className="mb-5">
                    Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> sont principalement les agents de notre service client.</p>                

                    <p className="mb-5"><span className="font-bold">8. Notification d’incident</span></p>
                    <p className="mb-5">
                    Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. 
                    Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.</p>
                    <p className="mb-5">
                    Aucune information personnelle de l'utilisateur du site <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>.</p>

                    <h3>Sécurité</h3>

                    <p className="mb-5">
                    Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données Personnelles de Santé, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la pseudonymisation, l’encryption et mot de passe. </p>
                        
                    <p className="mb-5">
                    Lors du traitement des Données Personnelles, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
                        
                    <p className="mb-5"><span className="font-bold">9. Liens hypertextes « cookies » et balises (“tags”) internet</span></p>
                    <p className="mb-5">
                    Le site <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>. Cependant, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
                    <p className="mb-5">Sauf si vous décidez de désactiver les cookies, vous acceptez que le site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.
                    </p>

                    <p className="mb-5"><span className="font-bold">9.1. « COOKIES »</span></p>
                        <p className="mb-5">
                    Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.</p>
                        <p className="mb-5">
                    <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> d’améliorer le contenu du Site, de la navigation de l’Utilisateur.</p>
                        <p className="mb-5">
                    Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.</p>
                        <p className="mb-5">
                    Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.</p>
                        <p className="mb-5">
                    Le cas échéant, <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.</p>
                        <p className="mb-5">
                    À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.</p>
                        <p className="mb-5">
                    Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).</p>
                        <p className="mb-5">
                    Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> dépose ce type de cookies.</p>
                        
                    <p className="mb-5"><span className="font-bold">Article 9.2. BALISES (“TAGS”) INTERNET</span></p>
                        

                    <p className="mb-5">

                    <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.</p>
                        
                    <p className="mb-5">
                    Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci. 
                        </p>
                    <p className="mb-5">
                    Cette technologie permet à <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur. </p>
                        <p className="mb-5">
                    Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a>, et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>
                    
                    <p className="mb-5"><span className="font-bold">10. Droit applicable et attribution de juridiction.</span></p>  
                        <p className="mb-5">
                    Tout litige en relation avec l’utilisation du site <a href="https://www.abcexperts.fr/">https://www.abcexperts.fr/</a> est soumis au droit français. 
                    En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents.
                    </p>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}