import React from 'react'
import {useState} from 'react' 
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'
import {Link} from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import DividerMenu from './dividerMenu'

export default function Layout(props) {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (value) => (event) => {
        if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
        ) {
        return;
        }

        setOpen(value);
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" style={{backgroundColor: 'rgba(0, 0, 0, 0.75)'}}>
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            <div className="w-20">
                                <img  className="rounded" src="./img/logo_abc_experts_justice.png" alt="abcexperts logo" />
                            </div>
                        </Typography>
                        <Button color="inherit" onClick={toggleDrawer(true)} className="linkBlue">
                            <MenuIcon /><span className="ml-2">Menu</span>
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box
                    sx={{ width: 250, height: '100vh' }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    style={{backgroundColor: 'rgba(0, 0, 0, 0.925)'}}

                >
                    <div className="text-white">
                        <div className="text-right py-3 px-6 mb-5 bg-black">
                            <span color="inherit" onClick={toggleDrawer(true)}>
                                <CloseIcon className="linkBlue" />
                            </span>
                        </div>
                        <div className="py-1 px-2 ">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/" className="link">Accueil</Link></MenuItem>
                        </div>
                        <DividerMenu />
                        <div className="py-1 px-2 ">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/articles" className="link">Articles</Link></MenuItem>
                        </div>
                        <DividerMenu />
                        <div className="py-1 px-2">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/assistance-maitrise-d-ouvrage" className="link">Maitrise d'ouvrage</Link></MenuItem>
                        </div>
                        <DividerMenu />
                        <div className="py-1 px-2">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/expertise-batiment" className="link">Expertise bâtiment</Link></MenuItem>
                        </div>
                        <DividerMenu />
                        <div className="py-1 px-2">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/mediation" className="link">Médiation</Link></MenuItem>
                        </div>
                        <DividerMenu />
                        <div className="py-1 px-2">
                            <MenuItem onClick={toggleDrawer(true)} className="linkBlue"><Link to="/contact" className="link">Contact</Link></MenuItem>
                        </div>

                    </div>
                </Box>
            </SwipeableDrawer>
            {props.children}
            
        </div>
    );
}